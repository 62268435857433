<template>
  <div class="content" style="min-height: 347px;">
        <!-- 背景图上 -->
        <div class="bg-top bg-top-jg" style="height:50px;">    
            <!-- 面包屑 -->
            <div class="breadcrumbs">
                <i></i>
                <a href="/">首页</a><span>></span>
                <a>服务专区</a><span>></span>
                <a class="active">{{ record.page_name }}</a>
            </div>
        </div>
        
        <div class="content-bot">
            <div class="lt-basis fw" style="margin-bottom:30px;">
                <h1 class="intro-title">{{ record.page_name }}</h1>
            </div>
            <div class="lt-basis fw ylcontent">
				 <table class="tbl">
					  <tr>
						  <td v-for="(item, index) in misc" :key="index" v-bind:width="tdwidth" style="vertical-align: top;">
							  <div style="text-align: center;">
								  <img v-bind:src="item.pic.external_url" class="boxpic"/>
							  </div>
							  <p style="font-size:18px;margin:15px 0;font-weight: bold;padding-left:10px;">{{item.title}}</p>
							  <p class="boxcontent">{{item.content}} </p>
						  </td>
					  </tr>
				  </table>
			</div>
			
			<img src="https://www.yiyuekangyang.com/img/ej_r7_c2.f3f536f4.png" style="height:273px; margin:0 96px;width:auto;"/>
            
        </div>
    </div>
</template>

<style src="../../../public/static/css/care-longterm.css" />
<style>
  .fw{margin: 0 96px;float:none;padding-left: 0 !important;}
  .tbl{width:100%;}
  .tbl td{padding:5px;}
  .boxcontent{font-size:14px;line-height: 26px;text-indent:30px;padding-left:10px;}
  .boxpic{width:98%; border-radius:10px;box-shadow: 4px 4px 10px #ccc;border: 1px solid #CCC; }
</style>


<script>

import * as Api from '@/api'
export default {
  data() {
    return {
      htmlContent: '',
      record: {},
	  misc:[],
	  tdwidth: '50%'
    }
  },
  watch:{
    $route(to, from) {
        // console.log('yl:' + this.$route.path)
        if (this.$route.path) {
            this.loadData()
        }
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
       Api.setting({key:'jigou'}).then(response=>{
         this.record = response.data.data.detail
		 
         if(this.record.title1 !== undefined){
		  this.misc[0] = {"title": this.record.title1, "pic": this.record.image1, "content": this.record.content1};
		  this.tdwidth = "100%";
         }
         if(this.record.title2 !== undefined){
		  this.misc[1] = {"title": this.record.title2, "pic": this.record.image2, "content": this.record.content2};
		  this.tdwidth = "50%";
         }
         if(this.record.title3 !== undefined){
          this.misc[2] = {"title": this.record.title3, "pic": this.record.image3, "content": this.record.content3};
          this.tdwidth = "33%";
         }
         if(this.record.title4 !== undefined){
          this.misc[3] = {"title": this.record.title4, "pic": this.record.image4, "content": this.record.content4};
          this.tdwidth = "25%";
         }
       })
    }
  }
}

</script>